<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="1300"
    :max-width="'90%'"
    :closeable="!loading"
    :loading="loading"
  >
    <div class="pa-6 pb-2">
      <!-- <cz-spinner :size="60" v-if="loading" show-title :title="loadingTitle" /> -->
      <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
        <cz-form-field :label="inputLabel" required>
          <validation-provider
            :rules="'required|mimes:application/pdf'"
            :name="inputLabel"
            v-slot="{ errors }"
            ref="validator"
          >
            <v-input :error-messages="errors" :value="orderDocumentFile">
              <v-fade-transition>
                <cz-dropzone
                  :hide-title="!!orderDocumentFile"
                  accept="application/pdf"
                  @change="onOrderDocumentChange"
                  width="100%"
                  @blur="$refs.validator.validate()"
                  :title="$t('dropzone.title')"
                  :height="'auto'"
                  :min-height="400"
                  use-file-reader
                  class="py-4"
                  :disabled="loading"
                  ref="dropzone"
                  style="cursor: pointer"
                >
                  <template #top v-if="!orderDocumentFile">
                    <v-img
                      :src="uploadDoc"
                      :max-height="180"
                      :max-width="200"
                      contain
                      @click="onUploadDocClicked"
                    />
                  </template>
                  <div slot="files" class="py-2 dropzone-files">
                    <div
                      v-if="orderDocument"
                      class="d-flex flex-column align-center"
                      style="row-gap: 15px"
                    >
                      <v-card style="overflow-y: auto">
                        <v-card-text style="position: relative">
                          <pdf-viewer :source="orderDocument.url" />
                          <v-progress-circular
                            v-if="uploadProgress > 0"
                            :rotate="180"
                            :size="150"
                            :width="15"
                            :value="uploadProgress"
                            color="primary"
                            style="position: absolute; top: 32%; left: 28%"
                          >
                            <span class="text-h6 font-weight-semibold">{{
                              `${uploadProgress}%`
                            }}</span>
                          </v-progress-circular>
                        </v-card-text>
                      </v-card>
                      <div
                        class="d-flex justify-space-between align-center full-width"
                      >
                        <div class="text-title font-weight-semibold">
                          {{ orderDocument.file.name }}
                        </div>
                        <cz-button
                          v-if="!loading"
                          :icon-src="mdiDeleteOutline"
                          color="primary"
                          @click="orderDocument = null"
                          text
                          noPaddings
                        />
                      </div>
                    </div>
                  </div>
                </cz-dropzone>
              </v-fade-transition>
            </v-input>
          </validation-provider>
        </cz-form-field>
        <div class="d-flex justify-center" v-if="!invalid">
          <v-scale-transition mode="in-out">
            <v-btn
              color="primary"
              type="submit"
              :disabled="invalid"
              class="mt-6"
              :text="loading"
              x-large
              :min-width="180"
              @click="onContinue"
            >
              <div
                class="d-flex justify-space-between full-width"
                style="column-gap: 20px"
                v-if="loading"
              >
                <span class="flex-grow-1">{{ loadingTitle }}</span>
                <cz-spinner :size="20" />
              </div>
              <span v-else class="flex-grow-1">{{
                $t('common.continue')
              }}</span>
            </v-btn>
          </v-scale-transition>
        </div>
      </validation-observer>
    </div>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzDropzone,
  CzFormField,
  CzChip,
  CzButton,
  CzIcon,
  CzSpinner
} from '@/components';
import { mdiInformation, mdiDeleteOutline } from '@mdi/js';
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import uploadDoc from '@/assets/illustrations/upload-document.svg';
export default {
  name: 'NewOrderDialog',
  components: {
    CzDialog,
    PdfViewer,
    CzDropzone,
    CzButton,
    CzFormField,
    CzChip,
    CzSpinner,
    CzIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingTitle: {
      type: String
    },
    inputLabel: {
      type: String,
      required: true
    },
    uploadProgress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    orderDocumentFile() {
      return this.orderDocument?.file;
    }
  },
  data() {
    return {
      uploadDoc,
      mdiInformation,
      mdiDeleteOutline,
      orderDocument: null,
      autoExtractData: true
    };
  },
  methods: {
    onOrderDocumentChange(files) {
      this.orderDocument = files[0];
    },
    onUploadDocClicked() {
      this.$refs.dropzone.onBrowseFileClicked();
    },
    onContinue() {
      if (this.loading) {
        return;
      }

      this.$emit('continue', this.orderDocument.file);
    }
  }
};
</script>

<style></style>
